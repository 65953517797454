<template>
  <div>
    <popup
      title="Login"
      description="Login to your account to check your lab reports"
      :inputs="inputs"
      posActionName="Login"
      @onCancel="$emit('onCancel')"
      @onCLickPositive="onClickLogin"
      :processing="processing"
    >
      <template #bottom>
        <div class="forgot-container">
          Forgot your password ?
          <button class="reset" @click="$emit('onReset')">
            Reset Password
          </button>
        </div>
      </template>
    </popup>
  </div>
</template>

<script>
import Popup from "./Popup.vue";
var qs = require("qs");
var axios = require("axios");

export default {
  name: "LoginPopup",
  metaInfo: {
    title: "Login",
    // override the parent template and just use the above title only
    titleTemplate: null,
  },
  components: {
    popup: Popup,
  },
  data() {
    return {
      processing: false,
      inputs: [
        {
          id: "username",
          placeholder: "Your email or phone number",
          type: "text",
          name: "Email / Mobile",
          required: true,
          val: "",
        },
        {
          id: "password",
          placeholder: "Your account password",
          type: "password",
          name: "Password",
          required: true,
          val: "",
        },
      ],
    };
  },
  methods: {
    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    onClickLogin() {
      this.processing = true;

      let username = this.inputs[0].val;
      let password = this.inputs[1].val;

      var data = qs.stringify({
        username: username,
        password: password,
        type: this.validateEmail(username) ? "email" : "phone",
      });
      var config = {
        method: "post",
        url: this.$URLS.baseURL + this.$URLS.AUTH.LOGIN,
        data: data,
      };

      let ctx = this;
      this.$HTTP(config)
        .then(function (response) {
          let data = JSON.parse(JSON.stringify(response.data.patient));
          localStorage.setItem("phone", data.phone);
          localStorage.setItem("email", data.email);
          localStorage.setItem("id", data.id);
          localStorage.setItem("name", data.name);
          localStorage.setItem("token", data.token);
          localStorage.setItem("gender", data.gender);
          localStorage.setItem("city", data.city);
          localStorage.setItem("address", data.address);
          ctx.processing = false;
          ctx.$router.push("/dashboard");
        })
        .catch(function (error) {
          ctx.processing = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.forgot-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  font-size: 1.2rem;
  font-family: "P-400";

  button {
    margin-left: 1rem;
    margin-right: 1rem;
    background: transparent;
    border: none;
    cursor: pointer;
    color: orange;
    font-family: "P-600";
    font-size: 1.4rem;
  }
}
</style>
