<template>
  <div>
    <home />
    <about />
    <services />

    <div class="feedback-btn" @click="showFeedbackPopup = true">
      <img src="../assets/icons/ic_feedback.png" alt="Feedback" />
      <span>Feedback</span>
    </div>

    <transition>
      <feedback
        v-if="showFeedbackPopup"
        @onSuccess="showFeedbackPopup = false"
        @onCancel="showFeedbackPopup = false"
      ></feedback>
    </transition>
  </div>
</template>

<script>
import Home from "../components/Home.vue";
import About from "../components/About.vue";
import Services from "../components/Services.vue";
import FeedbackPopup from "../components/FeedbackPopup.vue";
export default {
  components: {
    home: Home,
    about: About,
    services: Services,
    feedback: FeedbackPopup,
  },
  data() {
    return {
      showFeedbackPopup: false,
    };
  },
};
</script>

<style lang="scss">
.feedback-btn {
  background: var(--clr-ylw);
  z-index: 3;
  position: fixed;
  top: 50%;
  right: -7rem;
  border-top-left-radius: 10rem;
  border-bottom-left-radius: 10rem;

  display: flex;
  align-items: center;
  padding: 1rem;
  transition: 0.25s all ease-in-out;

  &:hover {
    box-shadow: var(--shadow);
    right: 0;
    span {
      visibility: visible;
      opacity: 1;
    }
  }

  img {
    width: 3rem;
    margin-top: 0.5rem;
  }

  span {
    transition: display 0s, opacity 0.5s linear;
    color: white;
    font-size: 1.4rem;
    font-family: "P-600", sans-serif;
    visibility: hidden;
    opacity: 0;
  }
}
</style>
