<template>
  <div class="radio">
    <div class="check-container">
      <div v-if="isChecked" class="check"></div>
    </div>
    {{ label }}
  </div>
</template>

<script>
export default {
  model: {
    prop: "modelValue",
    event: "change",
  },
  props: {
    label: { type: String, default: "", required: true },
    modelValue: { default: "" },
    value: { type: String, default: undefined },
  },
  computed: {
    isChecked() {
      return this.modelValue == this.value;
    },
    onChange() {
      console.log("sup");
      //   console.log("sup");
      //   console.log(this.value);
      this.$emit("update", this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.radio {
  width: 100%;
  height: 5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.4rem;
  color: black;
  font-family: "P-500";

  .check-container {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    margin-right: 1rem;
    border-radius: 50%;
    border: 0.2rem solid lightgray;

    .check {
      width: 1.5rem;
      height: 1.5rem;
      background: orange;
      border-radius: 50%;
      margin: auto;
    }
  }
}
</style>
