<template>
  <div>
    <popup
      ref="popup"
      title="Reset Password"
      description="Please enter your phone or email address. We will send you an OTP code for verification"
      :inputs="inputs"
      :posActionName="btnText"
      @onCancel="$emit('onCancel')"
      @onCLickPositive="onClick"
      :processing="processing"
    >
    </popup>
  </div>
</template>

<script>
import Popup from "./Popup.vue";
var qs = require("qs");
var axios = require("axios");

export default {
  name: "ResetPasswordPopup",
  components: {
    popup: Popup,
  },
  data() {
    return {
      step: 1,
      btnText: "Sent OTP Code",
      processing: false,
      inputs: [
        {
          id: "username",
          placeholder: "Your email or phone number",
          type: "text",
          name: "Email / Mobile",
          required: true,
          val: "",
        },
      ],
    };
  },
  methods: {
    onClick() {
      if (this.step == 1) {
        this.requestOtp();
      } else if (this.step == 2) {
        this.resetPassword();
      }
    },
    requestOtp() {
      let destination = this.inputs[0].val;
      if (!destination || destination.length <= 0) {
        this.$Notifications.showNotification(
          "warning",
          `Please enter a valid phone number or an email address`
        );
        return;
      }
      this.processing = true;
      var url = this.$URLS.AUTH.OTP.replace(":dest", destination);
      url = url.replace(
        ":type",
        this.validateEmail(destination) ? "email" : "phone"
      );
      this.$HTTP
        .patch(url)
        .then((res) => {
          console.log(res.data);
          this.$Notifications.showNotification(
            "success",
            `OTP code sent successfully`
          );
          this.processing = false;
          this.step = 2;

          var newInputs = this.inputs;
          newInputs.push(
            {
              id: "code",
              placeholder: "Enter the OTP code",
              type: "input-with-btn",
              name: "Code",
              required: true,
              val: "",
              BtnText: "Send Again",
            },
            {
              id: "password",
              placeholder: "Enter your new password",
              type: "password",
              name: "New Password",
              required: true,
              val: "",
            },
            {
              id: "repeat-password",
              placeholder: "Enter your new password again",
              type: "password",
              name: "Repeat New Password",
              required: true,
              val: "",
            }
          );
          console.log(JSON.parse(JSON.stringify(newInputs)));
          this.inputs = newInputs;

          this.$refs.popup.setPositiveButtonText("Reset Password");
          // this.$refs.popup.setInputs(this.newInputs);
        })
        .catch((e) => {
          this.processing = false;
        });
    },
    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    resetPassword() {
      this.processing = true;

      let destination = this.inputs[0].val;
      if (!destination || destination.length <= 0) {
        this.$Notifications.showNotification(
          "warning",
          `Please enter a valid phone number or an email address`
        );
        return;
      }

      let code = this.inputs[1].val;
      if (!code || code.length <= 0) {
        this.$Notifications.showNotification(
          "warning",
          `Please enter the OTP code`
        );
        return;
      }

      let pwd = this.inputs[2].val;
      if (!pwd || pwd.length <= 7) {
        this.$Notifications.showNotification(
          "warning",
          `Please enter a new password with length more then 7 characters`
        );
        return;
      }
      let repeat = this.inputs[3].val;
      if (!pwd || pwd.length <= 7) {
        this.$Notifications.showNotification(
          "warning",
          `Please enter your new password again`
        );
        return;
      }

      if (pwd != repeat) {
        this.$Notifications.showNotification(
          "warning",
          `The passwords don't match. Please make sure the passwords match`
        );
        return;
      }

      this.processing = true;

      let type = this.validateEmail(destination) ? "email" : "phone";

      let body = {
        destination: destination,
        otp: code,
        password: repeat,
        type: type,
      };

      this.$HTTP
        .post(this.$URLS.AUTH.RESET_PASSWORD, body)
        .then((res) => {
          this.$Notifications.showNotification(
            "success",
            `Password reset successful`
          );
          this.processing = false;
          this.$emit("onSuccess");
        })
        .catch((e) => {
          this.processing = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.forgot-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  font-size: 1.2rem;
  font-family: "P-400";

  button {
    margin-left: 1rem;
    margin-right: 1rem;
    background: transparent;
    border: none;
    cursor: pointer;
    color: orange;
    font-family: "P-600";
    font-size: 1.4rem;
  }
}
</style>
