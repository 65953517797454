<template>
  <div>
    <popup
      ref="popup"
      :title="title"
      :description="desc"
      layout="list"
      :inputs="inputs"
      posActionName="Send OTP Code"
      @onCancel="$emit('onCancel')"
      @onCLickPositive="onClickPositiveBtn"
      @onClickInputButton="onClickInputButton"
      :processing="processing"
      :enablePositiveButton="false"
    >
      <template class="type-container" v-if="step <= 2" #top>
        <label>How would you like to register ?</label>
        <div class="radios">
          <radio
            label="Phone"
            value="phone"
            v-model="type"
            @click="onClickType('phone')"
          />
          <radio
            label="Email"
            value="email"
            v-model="type"
            @click="onClickType('email')"
          />
        </div>
      </template>
    </popup>
  </div>
</template>

<script>
import Popup from "./Popup.vue";
import RadioBox from "./RadioBox.vue";
export default {
  name: "RegisterPopup",
  components: {
    popup: Popup,
    radio: RadioBox,
  },
  data() {
    return {
      title: "Register",
      desc: "Register a new account to view , download and manager all your lab reports",
      step: 1,
      type: "phone",
      processing: false,
      inputs: [
        {
          id: "phone",
          placeholder: "Your mobile phone number",
          type: "text",
          name: "Phone",
          required: true,
          val: "",
        },
      ],
      stepTwoInputs: [
        {
          id: "password",
          placeholder: "Your account password",
          type: "password",
          name: "Password",
          required: true,
          val: "",
        },
        {
          id: "fullname",
          placeholder: "Your full name",
          type: "text",
          name: "Fullname",
          required: true,
          val: "",
        },
        {
          id: "gender",
          placeholder: "Select a gender",
          type: "select",
          name: "Gender",
          options: [
            {
              key: "male",
              value: "Male",
            },
            {
              key: "female",
              value: "Female",
            },
            {
              key: "other",
              value: "other",
            },
          ],
          required: true,
          val: "",
        },
        {
          id: "address",
          placeholder: "Your full address",
          type: "text",
          name: "Address",
          required: true,
          val: "",
        },
        {
          id: "city",
          placeholder: "Your current city",
          type: "text",
          name: "City",
          required: true,
          val: "",
        },
        {
          id: "dob",
          placeholder: "Your date of birth",
          type: "date",
          name: "Date of Birth",
          required: true,
          val: "",
        },
      ],
      codeInput: {
        id: "code",
        placeholder: "Enter the OTP code",
        type: "input-with-btn",
        name: "Code",
        required: true,
        val: "",
        BtnText: "Send Again",
      },
    };
  },
  methods: {
    onClickType(type) {
      this.type = type;
      this.inputs = [
        {
          id: "username",
          placeholder:
            type == "phone" ? "Your mobile phone number" : "Your email address",
          type: "text",
          name: type,
          required: true,
          val: "",
        },
      ];
      this.$refs.popup.setInputs(this.inputs);
    },
    onClickInputButton(item) {
      if (item.id == "code") {
        this.sendOtp(true);
      }
      console.log(item);
    },
    sendOtp(sendingAgain) {
      let destination = this.inputs[0].val;
      if (!destination) {
        this.$Notifications.showNotification(
          "warning",
          `Please enter a valid ${this.type}`
        );
        return;
      }
      this.processing = true;
      var url = this.$URLS.AUTH.OTP.replace(":dest", destination);
      url = url.replace(":type", this.type);
      this.$HTTP
        .patch(url)
        .then((res) => {
          this.$Notifications.showNotification("success", "OTP code sent");
          this.processing = false;
          if (!sendingAgain) {
            this.inputs.push(this.codeInput);
            this.$refs.popup.setPositiveButtonText("Verify");
            this.step = 2;
            this.desc = `Please enter the OTP code so we can verify your ${this.type}`;
          }
        })
        .catch((err) => {
          console.error(err);
          this.processing = false;
        });
    },
    verify() {
      let destination = this.inputs[0].val;
      if (!destination) {
        this.$Notifications.showNotification(
          "warning",
          `Please enter a valid ${this.type}`
        );
        return;
      }
      let code = this.inputs[1].val;
      if (!code) {
        this.$Notifications.showNotification(
          "warning",
          `Please enter the OTP code`
        );
        return;
      }
      this.processing = true;
      var url = this.$URLS.AUTH.VERIFY.replace(":dest", destination);
      url = url.replace(":code", code);
      this.$HTTP
        .get(url)
        .then((res) => {
          this.processing = false;
          this.step = 3;
          this.desc = `Almost there. Please tell us a little about yourself`;
          this.inputs = [];
          var thirdStepInputs = [
            {
              id: this.type == "email" ? "phone" : "email",
              placeholder:
                this.type == "email"
                  ? "Please enter your phone number"
                  : "Please enter your email address",
              type: "text",
              name: this.type == "email" ? "Phone" : "Email",
              required: false,
              val: "",
            },
          ];

          thirdStepInputs = [...thirdStepInputs, ...this.stepTwoInputs];
          this.inputs = thirdStepInputs;
          this.$refs.popup.setInputs(this.inputs);
          this.$refs.popup.setLayout("grid");
          this.$refs.popup.setPositiveButtonText("Register");
          this.destination = destination;
        })
        .catch((err) => {
          console.error(err);
          this.processing = false;
        });
    },
    onClickPositiveBtn() {
      if (this.step == 1) {
        this.sendOtp(false);
      } else if (this.step == 2) {
        this.verify();
      } else {
        this.onClickRegister();
      }
    },
    onClickRegister() {
      this.processing = true;
      let emailOrPhone = this.inputs[0].val;
      let password = this.inputs[1].val;
      let fullname = this.inputs[2].val;
      let gender = this.inputs[3].val;
      let address = this.inputs[4].val;
      let city = this.inputs[5].val;
      let dob = this.inputs[6].val;

      this.$HTTP
        .post(this.$URLS.AUTH.REGISTER, {
          [this.type]: this.destination,
          [this.type == "email" ? "phone" : "email"]: emailOrPhone,
          name: fullname,
          password: password,
          gender: gender,
          address: address,
          city: city,
          dob: dob,
        })
        .then((res) => {
          this.processing = false;
          this.$Notifications.showNotification(
            "success",
            "Congratulations, your account has been created"
          );
          this.$emit("onSuccess", res.data);
        })
        .catch((err) => {
          this.processing = false;
          console.log("register err");
          console.log(err);
        });

      // setTimeout(() => {
      //   this.processing = false;
      //   this.$router.push("/dashboard");
      // }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  font-size: 1.2rem;
  color: gray;
  font-family: "P-500", sans-serif;
  width: 100%;
  margin-bottom: 0.4rem;
  text-transform: uppercase;
}

.radios {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin: 0.2rem 0rem 1rem 0rem;
}
</style>
