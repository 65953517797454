<template>
  <div>
    <popup
      title="Feedback"
      description="How would you rate your experience at NxGen ? Your opinion matters and we thank you for taking the time out to rate our services."
      :inputs="inputs"
      posActionName="Send Feedback"
      @onCancel="$emit('onCancel')"
      @onCLickPositive="onClickSave"
      :processing="processing"
    />
  </div>
</template>

<script>
import Popup from "./Popup.vue";

export default {
  name: "FeedbackPopup",
  components: {
    popup: Popup,
  },
  props: ["appointmentId"],
  data() {
    return {
      processing: false,
      inputs: [
        {
          id: "name",
          placeholder: "Your full name",
          type: "text",
          name: "Name",
          required: true,
          val: "",
        },
        {
          id: "email",
          placeholder: "Your email address",
          type: "text",
          name: "Email",
          required: true,
          val: "",
        },
        {
          id: "city",
          placeholder: "Which city your belong to ?",
          type: "text",
          name: "City",
          required: true,
          val: "",
        },
        {
          id: "message",
          placeholder: "Any suggestions or message you would like to give us ?",
          type: "textarea",
          name: "Message",
          required: true,
          val: "",
        },
        {
          id: "rating",
          placeholder: "How would you rate our services ?",
          type: "rating",
          name: "How would you rate us ?",
          required: true,
          val: "0",
        },
      ],
    };
  },
  methods: {
    onClickSave() {
      this.processing = true;
      let name = this.inputs[0].val;
      let email = this.inputs[1].val;
      let city = this.inputs[2].val;
      let message = this.inputs[3].val;
      let rating = this.inputs[4].val;

      this.$HTTP
        .post(this.$URLS.FEEDBACK.CREATE, {
          name: name,
          city: city,
          score: rating,
          message: message,
          email: email,
        })
        .then((res) => {
          this.processing = false;
          this.$Notifications.showNotification(
            "success",
            "Thank you for your feedback"
          );
          this.$emit("onSuccess");
        })
        .catch((err) => {
          this.processing = false;
          console.log("cancel appointment error");
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
